<!--
  This example requires Tailwind CSS v2.0+ 
  
  This example requires some changes to your config:
  
  ```
  // tailwind.config.js
  const colors = require('tailwindcss/colors')
  
  module.exports = {
    // ...
    theme: {
      extend: {
        colors: {
          'blue-gray': colors.blueGray,
        },
      },
    },
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ],
  }
  ```
-->
<template>
    <!--
    This example requires updating your template:

    ```
    <html class="h-full bg-blue-gray-50">
    <body class="h-full overflow-hidden">
    ```
    -->
    <div>
        <Html class="h-full bg-blue-gray-50">
            <Head>
                <Title>WeTix - Your Account</Title>
            </Head>
        </Html>
        <Body class="h-full overflow-hidden"></Body>
        <div class="h-full flex min-h-full">
            <TransitionRoot as="template" :show="mobileMenuOpen">
                <Dialog
                    as="div"
                    class="fixed inset-0 flex z-40 lg:hidden"
                    @close="mobileMenuOpen = false"
                >
                    <TransitionChild
                        as="template"
                        enter="transition-opacity ease-linear duration-300"
                        enter-from="opacity-0"
                        enter-to="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leave-from="opacity-100"
                        leave-to="opacity-0"
                    >
                        <DialogOverlay class="fixed inset-0 bg-blue-gray-600 bg-opacity-75" />
                    </TransitionChild>
                    <TransitionChild
                        as="template"
                        enter="transition ease-in-out duration-300 transform"
                        enter-from="-translate-x-full"
                        enter-to="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leave-from="translate-x-0"
                        leave-to="-translate-x-full"
                    >
                        <div
                            class="relative flex-1 flex flex-col max-w-xs w-full bg-white focus:outline-none"
                        >
                            <TransitionChild
                                as="template"
                                enter="ease-in-out duration-300"
                                enter-from="opacity-0"
                                enter-to="opacity-100"
                                leave="ease-in-out duration-300"
                                leave-from="opacity-100"
                                leave-to="opacity-0"
                            >
                                <div class="absolute top-0 right-0 -mr-12 pt-4">
                                    <button
                                        type="button"
                                        class="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                        @click="mobileMenuOpen = false"
                                    >
                                        <span class="sr-only">Close sidebar</span>
                                        <XIcon class="h-6 w-6 text-white" aria-hidden="true" />
                                    </button>
                                </div>
                            </TransitionChild>
                            <div class="pt-5 pb-4">
                                <div class="flex-shrink-0 flex items-center px-4">
                                    <img
                                        class="h-8 w-auto"
                                        src="https://tailwindui.com/img/logos/workflow-mark.svg?color=blue&shade=600"
                                        alt="Workflow"
                                    />
                                </div>
                                <nav aria-label="Sidebar" class="mt-5">
                                    <div class="px-2 space-y-1">
                                        <a
                                            v-for="item in navigation"
                                            :key="item.name"
                                            :href="item.href"
                                            class="group p-2 rounded-md flex items-center text-base font-medium text-blue-gray-600 hover:bg-blue-gray-50 hover:text-blue-gray-900"
                                        >
                                            <component
                                                :is="item.icon"
                                                class="mr-4 h-6 w-6 text-blue-gray-400 group-hover:text-blue-gray-500"
                                                aria-hidden="true"
                                            />
                                            {{ item.name }}
                                        </a>
                                    </div>
                                </nav>
                            </div>
                            <div class="flex-shrink-0 flex border-t border-blue-gray-200 p-4">
                                <a href="#" class="flex-shrink-0 group block">
                                    <div class="flex items-center">
                                        <div>
                                            <img
                                                class="inline-block h-10 w-10 rounded-full"
                                                src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2.5&w=256&h=256&q=80"
                                                alt
                                            />
                                        </div>
                                        <div class="ml-3">
                                            <p
                                                class="text-base font-medium text-blue-gray-700 group-hover:text-blue-gray-900"
                                            >Lisa Marie</p>
                                            <p
                                                class="text-sm font-medium text-blue-gray-500 group-hover:text-blue-gray-700"
                                            >Account Settings</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                    </TransitionChild>
                    <div class="flex-shrink-0 w-14" aria-hidden="true">
                        <!-- Force sidebar to shrink to fit close icon -->
                    </div>
                </Dialog>
            </TransitionRoot>

            <!-- Static sidebar for desktop -->
            <div class="hidden lg:flex lg:flex-shrink-0">
                <div class="flex flex-col w-20">
                    <div class="flex-1 flex flex-col min-h-0 overflow-y-auto bg-blue-600">
                        <div class="flex-1">
                            <div class="bg-blue-700 py-4 flex items-center justify-center">
                                <img
                                    class="h-8 w-auto"
                                    src="https://tailwindui.com/img/logos/workflow-mark.svg?color=white"
                                    alt="Workflow"
                                />
                            </div>
                            <nav
                                aria-label="Sidebar"
                                class="py-6 flex flex-col items-center space-y-3"
                            >
                                <a
                                    v-for="item in navigation"
                                    :key="item.name"
                                    :href="item.href"
                                    class="flex items-center p-4 rounded-lg text-blue-200 hover:bg-blue-700"
                                >
                                    <component :is="item.icon" class="h-6 w-6" aria-hidden="true" />
                                    <span class="sr-only">{{ item.name }}</span>
                                </a>
                            </nav>
                        </div>
                        <div class="flex-shrink-0 flex pb-5">
                            <a href="#" class="flex-shrink-0 w-full">
                                <img
                                    class="block mx-auto h-10 w-10 rounded-full"
                                    src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2.5&w=256&h=256&q=80"
                                    alt
                                />
                                <div class="sr-only">
                                    <p>Lisa Marie</p>
                                    <p>Account settings</p>
                                </div>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="flex-1 min-w-0 flex flex-col overflow-hidden">
                <!-- Mobile top navigation -->
                <div class="lg:hidden">
                    <div class="bg-blue-600 py-2 px-4 flex items-center justify-between sm:px-6">
                        <div>
                            <img
                                class="h-8 w-auto"
                                src="https://tailwindui.com/img/logos/workflow-mark.svg?color=white"
                                alt="Workflow"
                            />
                        </div>
                        <div>
                            <button
                                type="button"
                                class="-mr-3 h-12 w-12 inline-flex items-center justify-center bg-blue-600 rounded-md text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                @click="mobileMenuOpen = true"
                            >
                                <span class="sr-only">Open sidebar</span>
                                <MenuIcon class="h-6 w-6" aria-hidden="true" />
                            </button>
                        </div>
                    </div>
                </div>

                <main class="flex-1 flex overflow-hidden">
                    <div class="flex-1 flex flex-col overflow-y-auto xl:overflow-hidden">
                        <!-- Breadcrumb -->
                        <nav
                            aria-label="Breadcrumb"
                            class="bg-white border-b border-blue-gray-200 xl:hidden"
                        >
                            <div
                                class="max-w-3xl mx-auto py-3 px-4 flex items-start sm:px-6 lg:px-8"
                            >
                                <a
                                    href="#"
                                    class="-ml-1 inline-flex items-center space-x-3 text-sm font-medium text-blue-gray-900"
                                >
                                    <ChevronLeftIcon
                                        class="h-5 w-5 text-blue-gray-400"
                                        aria-hidden="true"
                                    />
                                    <span>Settings</span>
                                </a>
                            </div>
                        </nav>

                        <div class="flex-1 flex xl:overflow-hidden">
                            <!-- Main content -->
                            <slot />
                        </div>
                    </div>
                </main>
            </div>
        </div>
    </div>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'
import {
    BellIcon,
    BookmarkAltIcon,
    CashIcon,
    CogIcon,
    FireIcon,
    HomeIcon,
    InboxIcon,
    KeyIcon,
    MenuIcon,
    PhotographIcon,
    SearchCircleIcon,
    UserIcon,
    ViewGridAddIcon,
    XIcon,
} from '@heroicons/vue/outline'
import { ChevronLeftIcon } from '@heroicons/vue/solid'

const navigation = [
    { name: 'Home', href: '#', icon: HomeIcon },
    { name: 'Trending', href: '#', icon: FireIcon },
    { name: 'Bookmarks', href: '#', icon: BookmarkAltIcon },
    { name: 'Messages', href: '#', icon: InboxIcon },
    { name: 'Profile', href: '#', icon: UserIcon },
]
const subNavigation = [
    {
        name: 'Account',
        description: 'Ullamcorper id at suspendisse nec id volutpat vestibulum enim. Interdum blandit.',
        href: '#',
        icon: CogIcon,
        current: true,
    },
    {
        name: 'Notifications',
        description: 'Enim, nullam mi vel et libero urna lectus enim. Et sed in maecenas tellus.',
        href: '#',
        icon: BellIcon,
        current: false,
    },
    {
        name: 'Security',
        description: 'Semper accumsan massa vel volutpat massa. Non turpis ut nulla aliquet turpis.',
        href: '#',
        icon: KeyIcon,
        current: false,
    },
    {
        name: 'Appearance',
        description: 'Magna nulla id sed ornare ipsum eget. Massa eget porttitor suscipit consequat.',
        href: '#',
        icon: PhotographIcon,
        current: false,
    },
    {
        name: 'Billing',
        description: 'Orci aliquam arcu egestas turpis cursus. Lectus faucibus netus dui auctor mauris.',
        href: '#',
        icon: CashIcon,
        current: false,
    },
    {
        name: 'Integrations',
        description: 'Nisi, elit volutpat odio urna quis arcu faucibus dui. Mauris adipiscing pellentesque.',
        href: '#',
        icon: ViewGridAddIcon,
        current: false,
    },
    {
        name: 'Additional Resources',
        description: 'Quis viverra netus donec ut auctor fringilla facilisis. Nunc sit donec cursus sit quis et.',
        href: '#',
        icon: SearchCircleIcon,
        current: false,
    },
]

export default {
    components: {
        Dialog,
        DialogOverlay,
        TransitionChild,
        TransitionRoot,
        ChevronLeftIcon,
        MenuIcon,
        XIcon,
    },
    setup() {
        const mobileMenuOpen = ref(false)

        return {
            navigation,
            subNavigation,
            mobileMenuOpen,
        }
    },
}
</script>